import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import VeeValidate from 'vee-validate';
import App from './App.vue'
import AccessDenied from './components/AccessDenied.vue'
import { Field, Select, Switch, Autocomplete, Button, Upload } from 'buefy'

import 'buefy/dist/buefy.css'

Vue.use(Field)
  .use(Select)
  .use(Switch)
  .use(Autocomplete)
  .use(Button)
  .use(Upload)

Vue.use(VeeValidate);

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

Vue.config.productionTip = false

let hasAccess = false;
Vue.prototype.$airtableCreds = null;
const airtableCreds = await axios.get((process.env.VUE_APP_BASE_URL ?? '') + '/airtable');

if (airtableCreds.status == 200) {
  hasAccess = true;
  Vue.prototype.$airtableCreds = airtableCreds.data;
}

new Vue({
  render: h => h(hasAccess ? App : AccessDenied),
}).$mount('#app')