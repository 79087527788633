<template>
  <div id="app">
    <LabelForm title="Filter Label" />
    <div class="footer-info">
      <span>FOR MidOpt INTERNAL USE ONLY | BUILD <b>{{ info.build }}</b> | {{ new Date().getFullYear() }}</span>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import LabelForm from './components/LabelForm.vue'

export default {
  name: 'FIT',
  components: {
    LabelForm
  },
  data() {
    return {
      info: {},
    }
  },
  mounted() {
    this.getAppInfo();
  },
  methods: {
    getAppInfo() {
      const endpoint = `${(process.env.VUE_APP_BASE_URL ?? '')}/info`;
      axios.get(endpoint).then(response => {
        if (response.status == 200) {
          this.info = response.data;
        }
      })
    },
  }
}
</script>

<style>
html,
body {
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  width: 100% !important;
}

.footer-info {
  font-size: 0.75rem !important;
  margin-bottom: 2rem !important;
  color: black;
  justify-content: center;
  display: flex;
  align-items: end;
  flex: 1;
}
</style>
