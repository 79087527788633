<template>
    <div class="container" ref="element" :closable="false">
        <section class="section">
            <div class="columns is-centered mt-4">
                <b-image :src="logoImage" class="is-128x128" style="margin: 0 auto"></b-image>
            </div>
            <div class="columns is-centered mt-4">
                <span class="is-size-1 has-text-weight-bold">Filter Insert Tool v1.0</span>
            </div>
            <div class="columns is-centered mt-4">
                <div class="column is-two-fifths">
                    <b-loading :is-full-page="false" v-model="pageLoader"></b-loading>
                    <b-field class="sku-number">
                        <b-input name="sku" v-model="skuNumber" v-validate="'required|max:24'"></b-input>
                    </b-field>
                    <div class="columns">
                        <div class="column">
                            <!-- Filter dropdown start -->
                            <b-field label="Part #" :type="{ 'is-danger': errors.first('part') || errors.first('sku') }"
                                :message="errors.first('part') || errors.first('sku')">
                                <b-autocomplete ref="autocomplete" clearable v-model="filter.number" :data="filteredItems"
                                    :open-on-focus="true" field="number" v-validate="'required|min:2'" name="part"
                                    placeholder="e.g. BP140" @select="option => hexColorToRgb(option)"
                                    @focusout.native="onFilterNumberFocusout">
                                    <template #empty>No options</template>
                                    <template slot-scope="props">
                                        <div class="media">
                                            <div class="media-left">
                                                <div class="filter-color-icon"
                                                    :style="{ 'background-color': props.option.hex, 'border': '0.01px solid black' }">
                                                </div>
                                            </div>
                                            <div class="media-content">
                                                {{ props.option.number }}
                                            </div>
                                        </div>
                                    </template>
                                </b-autocomplete>
                            </b-field>
                        </div>
                        <div class="column" v-if="!isFilterNumberMatched">
                            <!-- Filter dropdown start -->
                            <b-field label="Color">
                                <b-autocomplete ref="autocomplete" clearable v-model="filter.extraColor"
                                    :data="filteredColorItems" :open-on-focus="true" field="number" name="extra_color"
                                    placeholder="Select one" @select="option => hexColorToRgb(option)">
                                    <template #empty>No options</template>
                                    <template slot-scope="props">
                                        <div class="media">
                                            <div class="media-left">
                                                <div class="filter-color-icon"
                                                    :style="{ 'background-color': props.option.hex }">
                                                </div>
                                            </div>
                                            <div class="media-content">
                                                {{ props.option.number }}
                                            </div>
                                        </div>
                                    </template>
                                </b-autocomplete>
                            </b-field>
                        </div>
                    </div>
                    <!-- Filter dropdown end -->
                    <!-- Filter size dropdown start -->
                    <b-field label="Size" :type="{ 'is-danger': errors.first('size') || errors.first('sku') }"
                        :message="errors.first('size') || errors.first('sku')">
                        <b-autocomplete clearable v-model="filter.size" :data="filteredSizes" placeholder="e.g. 25.4">
                            <template #empty>No options</template>
                        </b-autocomplete>
                    </b-field>
                    <!-- Filters size dropdown end -->
                    <!-- Filter Case size dropdown start -->
                    <b-field label="Case Size" :type="{ 'is-danger': errors.first('case-size') }"
                        :message="errors.first('case-size')">
                        <b-autocomplete clearable v-model="filter.caseSize" :data="filteredTemplates" :open-on-focus="true"
                            field="name" placeholder="e.g. 2x2" @select="option => setTemplateUrl(option)"
                            v-validate="'required|tpl-exist'" name="case-size">
                            <template #empty>No options</template>
                        </b-autocomplete>
                    </b-field>
                    <!-- Filter Case size dropdown end -->
                    <!-- Filters made in USA switch start -->
                    <b-field grouped group-multiline>
                        <div class="control ">
                            <b-switch type="is-primary" size="is-medium" v-model="filter.usaMade">Made in USA</b-switch>
                        </div>
                    </b-field>
                    <!-- Filters made in USA switch end -->
                    <b-button type="is-primary" :loading="isInProcess" expanded @click="generateLabel">Submit</b-button>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import convert from 'color-convert';
import Airtable from 'airtable'
import fontkit from '@pdf-lib/fontkit'
import logoImage from '@/assets/midopt.png';
import madeInLabel from '@/assets/made-in.png';
import boldFont from '@/assets/fonts/SourceSansPro-Bold.ttf';
import regularFont from '@/assets/fonts/SourceSansPro-Regular.ttf';
import { degrees, PDFDocument, rgb, cmyk, StandardFonts } from 'pdf-lib';

export default {
    data() {
        return {
            airtable: null,
            pdfDoc: null,
            filter: {
                number: '',
                size: '',
                caseSize: '',
                usaMade: true,
                color: '',
                extraColor: '',
            },
            template: {
                url: null,
                mainFontSize: 13,
                hingeFontSize: 10,
                regFont: regularFont,
                boldFont: boldFont,
            },
            filters: [],
            sizes: [],
            templates: [],
            maxSkuLens: {},
            isInProcess: false,
            pageLoader: true,
            logoImage: logoImage,
            isFilterNumberMatched: true,
        }
    },
    mounted() {
        if (this.initAirtable()) {
            this.loadFilters();
            this.loadTemplates();
        }
        this.attachCustomValidation();
    },
    updated() {
        this.pageLoader = false;
    },
    computed: {
        skuNumber() {
            return `${this.filter.number}${this.filter.size}`;
        },
        filteredItems() {
            return this.filters.filter((option) => {
                return option.number
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filter.number.toLowerCase()) >= 0
            })
        },
        filteredColorItems() {
            return this.filters.filter((option) => {
                return option.number
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filter.extraColor.toLowerCase()) >= 0
            })
        },
        filteredSizes() {
            return this.sizes.filter((option) => {
                return option
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filter.size.toLowerCase()) >= 0
            })
        },
        filteredTemplates() {
            return this.templates.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filter.caseSize.toLowerCase()) >= 0
            })
        }
    },
    methods: {
        onFilterNumberFocusout() {
            this.filter.extraColor = '';
            this.isFilterNumberMatched = this.filteredItems.length > 0;
        },
        initAirtable() {
            if (this.$airtableCreds) {
                this.airtable = new Airtable({
                    apiKey: this.$airtableCreds.api_key,
                    endpointUrl: this.$airtableCreds.api_url,
                }).base(this.$airtableCreds.base_id);
            }
            return this.airtable != null;
        },
        loadTemplates() {
            this.airtable(this.$airtableCreds.sizes_table).select({
                sort: [{ field: 'size', direction: 'asc' }]
            })
                .eachPage((records, fetchNextPage) => {
                    // Handle each page of records
                    records.forEach(record => {
                        // Access individual record properties
                        const templates = record.get('template');
                        if (templates && templates.length > 0) {
                            const firstTemplate = templates[0];
                            this.templates.push({
                                url: firstTemplate.url,
                                // fonts: record.get('fonts'),
                                name: record.get('size'),
                                mainOffset: record.get('main_offset'),
                                hingeOffset: record.get('hinge_offset'),
                                madeLabel: record.get('made_label') ? record.get('made_label')[0].url : madeInLabel,
                            });
                            if (record.get('max_sku_len')) {
                                this.maxSkuLens[record.get('size')] = parseInt(record.get('max_sku_len'));
                            }
                        }
                    });
                    // Fetch the next page of records
                    fetchNextPage();
                }, err => {
                    if (err) {
                        console.error('Error retrieving records:', err);
                    }
                });
        },
        loadFilters() {
            this.airtable(this.$airtableCreds.filter_table).select({
                fields: ['prefix', 'hex']
            })
                .eachPage((records, fetchNextPage) => {
                    // Handle each page of records
                    records.forEach(record => {
                        // Access individual record properties
                        this.filters.push({
                            number: record.get('prefix'),
                            hex: record.get('hex'),
                        });
                    });
                    // Fetch the next page of records
                    fetchNextPage();
                }, err => {
                    if (err) {
                        console.error('Error retrieving records:', err);
                    }
                });
        },
        async generateLabel() {
            this.isInProcess = true;
            await this.$validator.validateAll();

            if (this.errors.items.length > 0) {
                this.isInProcess = false;
                return;
            }

            try {
                if (!this.template.url) {
                    throw new Error('An error occurred fetching template')
                }

                // Trying fetching pdf template
                const arrayBuffer = await fetch(this.template.url)
                    .then(response => {
                        if (!response.ok) {
                            return 'Network response was not OK while fetching template';
                        }
                        return response.arrayBuffer();
                    })
                    .catch(error => {
                        // Handle any errors that occurred during the request
                        return 'An error occurred fetching template';
                    });

                if (typeof arrayBuffer == 'string') {
                    throw new Error(arrayBuffer)
                }

                // Handle the response data
                const template = this.template;
                let sku = this.filter.number;
                let mainYidx = 1, hingeYidx = 1;
                let mainFontSize = template.mainFontSize;
                let hingeFontSize = template.hingeFontSize;

                if (this.filter.size.length) {
                    sku = `${sku}-${this.filter.size}`
                }

                // Load a PDFDocument from the existing PDF bytes
                try {
                    this.pdfDoc = await PDFDocument.load(arrayBuffer)
                } catch (error) {
                    throw new Error('An error occurred loading template')
                }

                this.pdfDoc.registerFontkit(fontkit)
                const regFontBytes = await fetch(this.template.regFont).then((res) => res.arrayBuffer());
                const boldFontBytes = await fetch(this.template.boldFont).then((res) => res.arrayBuffer());

                // Embed the bold font
                let boldFont = await this.getFontDimensions(boldFontBytes, sku, mainFontSize, hingeFontSize)

                if (this.maxSkuLens.hasOwnProperty(this.filter.caseSize)) {
                    const maxSkuLen = this.maxSkuLens[this.filter.caseSize];

                    const mainScale = Math.min(1, maxSkuLen / boldFont.mainTextWidth);
                    const hingeScale = Math.min(1, maxSkuLen / boldFont.hingeTextWidth);

                    if (mainScale < 1) {
                        mainFontSize *= mainScale;
                        mainYidx += (template.mainFontSize - mainFontSize) / 2 * 0.005;
                    }
                    if (hingeScale < 1) {
                        hingeFontSize *= hingeScale;
                        hingeYidx += (template.hingeFontSize - hingeFontSize) / 2 * 0.005;
                    }
                    if (mainScale < 1 || hingeScale < 1) {
                        boldFont = await this.getFontDimensions(boldFontBytes, sku, mainFontSize, hingeFontSize);
                    }
                }

                // Get the first page of the documents
                const page = this.pdfDoc.getPages()[0]

                // Get the width and height of the first page
                const { width, height } = page.getSize()

                try {
                    // Draw a main part number text
                    const mainTextX = (width / 2 + boldFont.mainTextWidth / 2);
                    const mainTextY = (height * template.mainOffset * mainYidx / 100);
                    page.drawText(sku, {
                        x: mainTextX - (this.filter.color ? (mainFontSize * 0.5) : 0),
                        y: height - mainTextY,
                        size: mainFontSize,
                        font: boldFont.font,
                        color: rgb(0, 0, 0),
                        rotate: degrees(-180),
                    })

                    if (this.filter.color) {
                        const rgbColor = rgb(...this.filter.color.map(i => i / 255))
                        const cmykColor = cmyk(...convert.rgb.cmyk(this.filter.color).map(i => Math.min(1, Math.max(0, i / 100))))
                        page.drawCircle({
                            x: mainTextX + (mainFontSize * 0.1),
                            y: height - (mainTextY + mainFontSize * 0.32),
                            size: mainFontSize * 0.35,
                            color: cmykColor,
                            opacity: 0.8
                        });
                        page.drawCircle({
                            x: mainTextX + (mainFontSize * 0.1),
                            y: height - (mainTextY + mainFontSize * 0.32),
                            size: mainFontSize * 0.35,
                            borderColor: rgb(0, 0, 0),
                            borderWidth: 0.075,
                            color: rgbColor,
                            opacity: 0.9
                        });
                    }

                    // Draw a hinge part number text
                    page.drawText(sku, {
                        x: width / 2 + boldFont.hingeTextWidth / 2,
                        y: (height - (height * template.hingeOffset * hingeYidx / 100)),
                        size: hingeFontSize,
                        font: boldFont.font,
                        color: rgb(0, 0, 0),
                        rotate: degrees(-180),
                    })

                    if (this.filter.usaMade) {
                        if (this.template.hasOwnProperty('madeLabel')) {
                            let scaleIdx = 1;
                            const madeLabelBytes = await fetch(this.template.madeLabel).then((res) => res.arrayBuffer());

                            // Embed the JPG image bytes and PNG image bytes
                            const madeInLabel = await this.pdfDoc.embedPng(madeLabelBytes)

                            if (madeInLabel.width > 40) {
                                scaleIdx = 40 * 100 / madeInLabel.width / 100;
                            }

                            // Get the width/height of the PNG image scaled down to calculated index of its original size
                            const labelDims = madeInLabel.scale(scaleIdx)

                            // Draw the PNG image near the lower right corner of the JPG image
                            page.drawImage(madeInLabel, {
                                x: width / 2 - labelDims.width / 2,
                                y: 7.7,
                                width: labelDims.width,
                                height: labelDims.height,
                            })
                        }
                    }
                } catch (error) {
                    console.log(error)
                    throw new Error('An error occurred creating label')
                }

                // Serialize the PDF document to a Uint8Array
                const pdfBytes = await this.pdfDoc.save();

                try {
                    // Convert the Uint8Array to a Blob
                    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

                    // Create a download link for the generated PDF
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(pdfBlob);
                    downloadLink.download = `${sku}_${template.name}`.replaceAll(/\./g, ',');

                    // Trigger the download
                    downloadLink.click();

                    // Clean up the URL object
                    URL.revokeObjectURL(downloadLink.href);
                } catch (error) {
                    throw new Error('An error occurred downloading label')
                }
                this.dbCommit(sku);
                this.message('Filter insert successfully created');
            } catch (error) {
                console.log(error)
                this.message(error, 'danger')
            }
            this.isInProcess = false;
        },
        increaseCmykBrightness(cmykColor, amount) {
            // Make sure the amount is within the valid range (-100 to 100)
            amount = Math.max(Math.min(amount, 100), -100);

            // Extract individual CMYK components from the input color array
            const [c, m, y, k] = cmykColor;

            // Calculate the new "K" component with increased brightness
            const newK = Math.max(Math.min(k - amount, 100), 0);

            // Return the updated CMYK color as an array with increased brightness
            return [c, m, y, newK];
        },
        attachCustomValidation() {
            this.$validator.extend('tpl-exist', {
                validate: value => this.templates.filter(t => t.name == value).length > 0,
            });
        },
        dbCommit(sku) {
            return this.airtable(this.$airtableCreds.fit_db_table).create({
                "sku": sku,
                "case_size": this.filter.caseSize
            });
        },
        async getFontDimensions(type, text, mainFontSize = null, hingeFontSize = null) {
            // Embed the font of specified type
            const font = await this.pdfDoc.embedFont(type)
            mainFontSize = mainFontSize != null ? mainFontSize : this.template.mainFontSize
            hingeFontSize = hingeFontSize != null ? hingeFontSize : this.template.hingeFontSize
            // Measure the width of the text
            const mainTextWidth = font.widthOfTextAtSize(text, mainFontSize);
            const hingeTextWidth = font.widthOfTextAtSize(text, hingeFontSize);

            return { font, mainTextWidth, hingeTextWidth };
        },
        increaseBrightness(rgbColor, factor) {
            const { r, g, b } = rgbColor;

            // Increase the brightness by adding the factor to each channel
            const increasedR = Math.min(rgbColor[0] + factor, 255);
            const increasedG = Math.min(rgbColor[1] + factor, 255);
            const increasedB = Math.min(rgbColor[2] + factor, 255);

            return [increasedR, increasedG, increasedB]
        },
        increaseSaturation(color, factor) {
            const r = Math.min(255, Math.max(0, color[0] * (1 - factor) + factor));
            const g = Math.min(255, Math.max(0, color[1] * (1 - factor) + factor));
            const b = Math.min(255, Math.max(0, color[2] * (1 - factor) + factor));

            return [r, g, b];
        },
        reduceSaturation(color, factor) {
            const r = Math.min(255, Math.max(0, color[0] * (1 + factor) - factor));
            const g = Math.min(255, Math.max(0, color[1] * (1 + factor) - factor));
            const b = Math.min(255, Math.max(0, color[2] * (1 + factor) - factor));

            return [r, g, b];
        },
        setTemplateUrl(template) {
            this.template.url = null;
            if (template && template.hasOwnProperty('name')) {
                const templates = this.templates.filter(c => c.name == template.name);
                if (templates.length > 0) {
                    this.template = { ...this.template, ...templates[0] };
                }
            }
        },
        // Function to convert a hexadecimal color to RGB values
        hexColorToRgb(filter) {
            this.filter.color = null;
            if (filter && filter.hasOwnProperty('hex')) {
                const value = filter.hex.replace('#', '');
                this.filter.hex = value;
                this.filter.color = convert.hex.rgb(value);
            }
        },
        message(message, status = 'success') {
            this.$buefy.toast.open({
                duration: 10000,
                message: message,
                type: `is-${status}`,
                position: 'is-bottom',
                pauseOnHover: true
            })
        },
    },
}
</script>
<style>
.label-filter {
    width: 25%;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
}

.field:not(:last-child) {
    margin-bottom: 1.75rem !important;
}

.media-content {
    margin: auto;
    width: 50vw;
    width: 50vw;
}

.filter-color-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.sku-number {
    display: none;
}
</style>